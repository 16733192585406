import React, { Suspense } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// components
const Home = React.lazy(() => import('./components/Home'));
const AboutUs = React.lazy(() => import('./components/AboutUs'));
const Service = React.lazy(() => import('./components/Service'));
const MachineView = React.lazy(() => import('./components/MachineView'));
const Products = React.lazy(() => import('./components/Products'));
const ProductList = React.lazy(() => import('./components/ProductList'));
const Clients = React.lazy(() => import('./components/Clients'));
const ContactUs = React.lazy(() => import('./components/ContactUs'));

function App() {
  return (
    <div className="App">
      <Suspense >
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='about-us' element={<AboutUs />} />
          <Route path='services' element={<Service />} />
          <Route path='services/:name' element={<MachineView />} />
          <Route path='products' element={<Products />} />
          <Route path='products/:name' element={<ProductList />} />
          <Route path='clients' element={<Clients />} />
          <Route path='contact-us' element={<ContactUs />} />
          <Route path='*' element={<Home />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
